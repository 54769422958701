<template>
  <modal name="alarm-auto-test" class="modal-inner modal-update-addon" transition="pop-out" :width="modalWidth" :focus-trap="true" :min-height="600" height="auto" :scrollable="true" :resizable="true" :adaptive="true" :reset="true">
    <LoadingSpinner :isLoading="isLoading" v-if="isLoading" />
    <span class="close-button" @click="hide('alarm-auto-test')"><BaseIcon icon="times-circle" class="text-white"/></span>
    <div class="modal-header text-lg bg-primary-50 font-serif">{{ alarmInfo.unit_name }}</div>
    <div class="modal-body">
      <ValidationObserver ref="AlarmAutoTest">
        <div class="p-4">
          <div v-if="step === 1" class="">
            {{ $t("alarm_auto_test.alarm_auto_test") }}
            <div class="mt-2 text-center">
              <BaseInput v-model="alarm_mobile" type="text" :field_name="$t('alarm_auto_test.alarm_phone')" rules="required|numeric|max:15" />
            </div>
          </div>
          <div v-if="step === 2">
            <p class="text-center">
              <span v-if="timeLeft">{{ timeLeft }} {{ $t("alarm_auto_test.remaining_time") }}</span>
            </p>
            <p class="text-center">{{ $t("alarm_auto_test.no_push_button") }}</p>
          </div>
          <div v-if="step === 3">
            <div class="text-center">
              <span class="text-green-800">{{ $t("alarm_auto_test.success_message") }}</span>
            </div>
            <div class="cursor-pointer mt-3 text-center" @click="step = 1">{{ $t("alarm_auto_test.start_again") }}</div>
          </div>
          <div v-if="step === 4" class="text-center">
            {{ $t("alarm_auto_test.no_push_event_received") }} <span @click="step = 1" class="cursor-pointer hover:text-green-800 mt-3 text-center">{{ $t("alarm_auto_test.start_again") }}</span>
          </div>
        </div>
      </ValidationObserver>
    </div>
    <div class="modal-footer text-right p-3">
      <button v-if="step === 1" class="btn-blue-outline" @click="acativeAlarmTesting()">
        <span style="font-size: 16px;">{{ $t("alarm_auto_test.active") }}</span>
      </button>
      <button v-if="step !== 1" class="btn-red-outline" @click="hide('alarm-auto-test')">{{ $t("user_actions.cancel") }}</button>
    </div>
  </modal>
</template>

<script>
const MODAL_WIDTH = 600;

export default {
  props: {},
  data() {
    return {
      step: 1,
      testingIsActive: false,
      alarm_mobile: null,
      alarmInfo: { name: "" },
      device: null,
      gps: null,
      timer: null,
      timeLeft: "",
      duration: 20 * 60,
      intervalId: null, // To store the interval ID
      isLoading: false,
    };
  },
  components: {},
  methods: {
    setAlarmInfo(alarmInfo) {
      this.step = 1;
      this.alarmInfo = alarmInfo;

      // Get current time and previously stored values
      const now = this.moment.utc();
      const previousImei = localStorage.getItem("alarm_test_imei");
      const previousTime = localStorage.getItem("last_tunnel_disable");
      const lastTunnelDisableTimestamp = this.moment.utc(previousTime);

      // Calculate time difference in seconds
      const testingPeriodInSeconds = now.diff(lastTunnelDisableTimestamp, "seconds");

      // Default duration for testing (20 minutes)
      const defaultDurationSeconds = 20 * 60;

      // Check if the current IMEI matches the previous IMEI and if the testing period is within 20 minutes
      if (this.alarmInfo.imei_number === previousImei && testingPeriodInSeconds < defaultDurationSeconds) {
        // If testing is not active, initiate countdown
        if (!this.timeLeft) {
          this.duration = defaultDurationSeconds - testingPeriodInSeconds;
          this.startCountdown();
        }
        // Set step and testing status after ensuring UI updates
        this.$nextTick(() => {
          this.step = 2;
          this.testingIsActive = true;
        });
      } else {
        // Reset duration if conditions are not met
        this.duration = defaultDurationSeconds;
      }

      // Always check the alarm testing status at the end
      this.getAlarmTestingStatus();
    },

    async acativeAlarmTesting() {
      //console.log("acativeAlarmTesting::start");

      this.$refs.AlarmAutoTest.validate().then(async (success) => {
        if (!success) {
          return;
        }
        this.testingIsActive = true;
        this.isLoading = true;
        try {
          let apiUrl = `${process.env.VUE_APP_SERVER_URL}/device-event-tunneling/disable/${this.alarmInfo.imei_number}`;
          let response = await this.axios.post(apiUrl, { notificationPhone: this.alarm_mobile });

          //console.log("acativeAlarmTesting::response ", response.data);
          if (response.data.success) {
            this.device = response.data.device;
            if (this.device.last_tunnel_disable !== null) {
              localStorage.setItem("last_tunnel_disable", this.device.last_tunnel_disable);
              localStorage.setItem("alarm_test_imei", this.alarmInfo.imei_number);
            }
            this.gps = response.data.gps;
            this.step = 2;
            this.startCountdown();
            this.isLoading = false;
          }
        } catch (error) {
          this.handleError(error);
          this.isLoading = false;
        }
      });
    },
    startInterval() {
      // Clear existing interval to avoid duplicates
      this.clearInterval();

      this.getAlarmTestingStatus();
      // Set up a new interval
      this.intervalId = setInterval(() => {
        this.getAlarmTestingStatus();
      }, 5000);
    },
    clearInterval() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
        this.intervalId = null;
      }
    },
    async getAlarmTestingStatus() {
      try {
        let apiUrl = `${process.env.VUE_APP_SERVER_URL}/device-event-tunneling/status/${this.alarmInfo.imei_number}`;
        let response = await this.axios.get(apiUrl);

        if (response.data.success) {
          this.device = response.data.device;
          this.gps = response.data.gps;

          let now = this.moment.utc(); // Represents the current time in UTC
          let lastTunnelDisableTimestamp = this.moment.utc(localStorage.getItem("last_tunnel_disable"));
          let lastPushButtonTimestamp = this.moment.utc(this.gps.lastPushButtonTimestamp);

          let testingPeriod = now.diff(lastTunnelDisableTimestamp, "seconds");
          let lastPushButton = now.diff(lastPushButtonTimestamp, "seconds");

          if (this.testingIsActive && 1200 >= testingPeriod && testingPeriod >= lastPushButton) {
            this.step = 3;
            localStorage.setItem("last_tunnel_disable", null);
            this.testingIsActive = false;
          }

          if (this.testingIsActive && testingPeriod >= 1200) {
            this.step = 4;
            this.testingIsActive = false;
          }
        }
      } catch (error) {
        this.handleError(error);
      }
    },
    hide(id) {
      this.$modal.hide(id);
    },

    startCountdown() {
      if (this.timer) {
        clearInterval(this.timer); // Clear existing timer if any
      }
      let secondsLeft = this.duration;
      this.updateTimeLeft(secondsLeft);

      this.timer = setInterval(() => {
        secondsLeft--;
        this.updateTimeLeft(secondsLeft);

        if (secondsLeft <= 0) {
          clearInterval(this.timer);
          this.timer = null;
          this.timeLeft = null;
        }
      }, 1000);
      //this.step = 2;
    },
    updateTimeLeft(seconds) {
      const minutes = Math.floor(seconds / 60);
      const secondsLeft = seconds % 60;
      this.timeLeft = `${minutes}m ${secondsLeft}s`;
    },
  },
  mounted() {
    if (this.step === 2) {
      this.startInterval();
    }
  },

  created() {
    this.modalWidth = window.innerWidth < MODAL_WIDTH ? window.innerWidth : MODAL_WIDTH;
  },
  watch: {
    // Watch for changes on 'step'
    step(newVal) {
      if (newVal === 2) {
        this.startInterval();
      } else {
        this.clearInterval();
      }
    },
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
    if (this.intervalId) {
      clearInterval(this.intervalId); // Clear the interval on component destroy
    }
  },
};
</script>
