<template>
  <div class="alarm-report">
    <div class="">
      <div class="flex">
        <div class="w-full">
          <vue-good-table
            class="text-xs"
            :columns="columns"
            :rows="rows"
            :search-options="{
              enabled: false,
            }"
            :pagination-options="{
              enabled: true,
              perPage: 20,
              perPageDropdown: [20, 50, 100],
              dropdownAllowAll: false,
            }"
          >
            <template slot="table-row" slot-scope="props">
              <!-- todo: only show confirm button for reseller with their alarm center -->
              <span v-if="props.column.field == 'confirm' && !/(SRT334)|(SRT330)|(TWIG Beacon)/.test(props.row.type) && (user.role != 'reseller' || (user.role == 'reseller' && user.reseller_special_access.indexOf('test_reminder') > -1))">
                <button class="btn-red-outline" @click.prevent="confirmTestPrompt(props.row)">{{ $t("alarm_reminder_page.test_alarm_confirmed") }}</button>
                <button class="btn-blue ml-3" v-if="isGPSAlarm(props.row.imei_number)" @click="showAutoTestModal(props.row.imei_number)">Auto provlarm</button>
              </span>
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
          </vue-good-table>
        </div>
      </div>
    </div>
    <AlarmAutoTest ref="AutoProvlarm" />
  </div>
</template>

<script>
const MODAL_WIDTH = 700;
import AlarmAutoTest from "@/components/modal/alarm_auto_test";
export default {
  name: "AlarmReminderUnit",
  title() {
    return this.$t("page_titles.alarm_reminder_department");
  },
  data() {
    return {
      unit_id: this.$route.params.unit_id,
      unit: null,
      alarms: null,
      rows: [],
      gps_alarms: null,
    };
  },
  components: {
    AlarmAutoTest,
  },
  computed: {
    user() {
      return this.$store.state.user;
    },

    columns() {
      return [
        { label: this.$t("alarm_reminder_page.alarm"), field: "unit_name", thClass: "text-sm" },
        { label: this.$t("alarm_reminder_page.phone"), field: "phone", thClass: "text-sm" },
        { label: this.$t("alarm_reminder_page.latest_test_alarm"), field: "last_test_alarm", formatFn: this.formatDate, thClass: "text-sm" },
        { label: "", field: "confirm" },
      ];
    },
  },
  watch: {
    "$i18n.locale"() {
      document.title = this.$t("page_titles.alarm_reminder_department");
      this.setPageTitle(`${this.unit.name} - ${this.$t("top_titles.alarm_reminder")}`, "alarm_reminder_unit");
    },
  },
  methods: {
    async getUnit() {
      try {
        let apiUrl = `${process.env.VUE_APP_SERVER_URL}/unit/alarms/${this.unit_id}`;
        if (this.user.role == "reseller") apiUrl = `${process.env.VUE_APP_SERVER_URL}/reseller/${this.user.reseller_id}/unit/alarms/${this.unit_id}`;

        let response = await this.axios.get(apiUrl);
        this.unit = response.data.unit;
        this.alarms = response.data.unit_alarms;
        this.gps_alarms = response.data.gps_alarms;

        this.alarms = _.filter(this.alarms, function(o) {
          return ["SRT330", "SRT334", "TWIG Beacon"].indexOf(o.type) == -1;
        });
        this.rows = this.alarms;

        this.setPageTitle(`${this.unit.name} - ${this.$t("top_titles.alarm_reminder")}`, "alarm_reminder_unit");
      } catch (error) {
        this.handleError(error);
      }
    },

    confirmTestPrompt(alarm) {
      this.$modal.show("dialog", {
        title: `${this.$t("alarm_reminder_page.are_you_sure")}`,
        text: `<p style="text-align: center;">${this.$t("alarm_reminder_page.acknowledge_test_alarm")} ${alarm.unit_name}</p>`,
        buttons: [
          {
            title: `<div class="bg-accent-500 text-white text-sm font-sans py-2">${this.$t("no")}</div>`,
            handler: () => {
              this.$modal.hide("dialog");
            },
          },
          {
            title: `<div class="bg-red-500 text-white text-sm font-sans py-2">${this.$t("yes")}</div>`,
            handler: () => {
              this.confirmTestAlarm(alarm);
            },
          },
        ],
      });
    },

    async confirmTestAlarm(alarm) {
      try {
        let response = await this.axios.get(`${process.env.VUE_APP_SERVER_URL}/alarm/confirm-test-alarm/${alarm._id}`);

        this.$modal.hide("dialog");

        if (response.data.success) {
          this.getUnit();
          this.handleSuccess(`Provlarm Bekräftat för ${alarm.unit_name}`);
        }
      } catch (error) {
        this.$modal.hide("dialog");
        this.handleError(error);
      }
    },
    showAutoTestModal(imeiToFind) {
      let selectedAlarm = this.alarms.find((alarm) => alarm.imei_number === imeiToFind);
      this.$refs.AutoProvlarm.setAlarmInfo(selectedAlarm);
      this.$modal.show("alarm-auto-test");
    },

    formatDate(date) {
      if (date == null) return "-";
      else return this.moment(date).format("YYYY-MM-DD");
    },
    isGPSAlarm(imeiToFind) {
      return this.gps_alarms.some((alarm) => alarm.imei_number === imeiToFind);
    },
  },

  created() {
    this.modalWidth = window.innerWidth < MODAL_WIDTH ? window.innerWidth : MODAL_WIDTH;
    this.getUnit();
  },
};
</script>
