var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"alarm-report"},[_c('div',{},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"w-full"},[_c('vue-good-table',{staticClass:"text-xs",attrs:{"columns":_vm.columns,"rows":_vm.rows,"search-options":{
            enabled: false,
          },"pagination-options":{
            enabled: true,
            perPage: 20,
            perPageDropdown: [20, 50, 100],
            dropdownAllowAll: false,
          }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'confirm' && !/(SRT334)|(SRT330)|(TWIG Beacon)/.test(props.row.type) && (_vm.user.role != 'reseller' || (_vm.user.role == 'reseller' && _vm.user.reseller_special_access.indexOf('test_reminder') > -1)))?_c('span',[_c('button',{staticClass:"btn-red-outline",on:{"click":function($event){$event.preventDefault();return _vm.confirmTestPrompt(props.row)}}},[_vm._v(_vm._s(_vm.$t("alarm_reminder_page.test_alarm_confirmed")))]),(_vm.isGPSAlarm(props.row.imei_number))?_c('button',{staticClass:"btn-blue ml-3",on:{"click":function($event){return _vm.showAutoTestModal(props.row.imei_number)}}},[_vm._v("Auto provlarm")]):_vm._e()]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1)])]),_c('AlarmAutoTest',{ref:"AutoProvlarm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }